import { Domain } from '~/types'

export const DAILYMOTION_CONTEXTUAL_PLAYER_ID = 'xxsnk'
export const BLACKLISTED_SECTIONS: string[] = ['Crime', 'Troubles', 'Must Read']

export const BLACKLISTED_ARTICLE_TYPES: string[] = [
  'affiliate article',
  'sponsored article',
]

export const WHITELISTED_DOMAINS: Domain[] = [
  'birminghamworld.uk',
  'blackpoolgazette.co.uk',
  'bristolworld.com',
  'burnleyexpress.net',
  'chad.co.uk',
  'derbyshiretimes.co.uk',
  'derbyworld.co.uk',
  'edinburghnews.scotsman.com',
  'glasgowworld.com',
  'lancasterguardian.co.uk',
  'lep.co.uk',
  'liverpoolworld.uk',
  'londonworld.com',
  'lutontoday.co.uk',
  'manchesterworld.uk',
  'miltonkeynes.co.uk',
  'nationalworld.com',
  'newcastleworld.com',
  'northamptonchron.co.uk',
  'northantstelegraph.co.uk',
  'nottinghamworld.com',
  'peterboroughtoday.co.uk',
  'portsmouth.co.uk',
  'shieldsgazette.com',
  'sunderlandecho.com',
  'sussexexpress.co.uk',
  'thestar.co.uk',
  'wigantoday.net',
  'yorkshireeveningpost.co.uk',
]
