import * as Throttle from 'promise-parallel-throttle'
import { initAssertiveYield } from '~/util/assertive-yield'
import { initDailyMotion } from '~/util/dailymotion'
import { getGdprConsentToken } from '~/util/get-gdpr-consent-token'
import { sourcePoint_v2_0 } from '~/util/sourcePoint'
import { userStatus } from '~/util/userStatus'
import { initialisePreConsent } from '~/util/pre-consent-scripts'
import { initAds } from './initAds'
import { optOutTrialSites } from '~/config/JPIMConfig'
import { initOptOutTag } from '~/util/ootag'
import { initAirGrid } from '~/util/airgrid'
import { initMantis } from '~/util/get-external-scripts'

const pageType = window?.JSGlobals?.pageType ?? ''

// Determine if the pageType should trigger Taboola scripts
const isTaboolaPage =
  pageType === 'article' ||
  pageType === 'video article' ||
  pageType === 'live blog article' ||
  pageType === 'photo article'

// Initialise pre-consent scripts with pageType
initialisePreConsent(isTaboolaPage ? pageType : '')

window.JSGlobals = window.JSGlobals ?? {
  mode: 'development',
}

const initialisers = [
  // Load consent manager
  {
    consent: {
      method: async () => {
        try {
          console.log(`starting consent block at ${performance.now()}ms`)
          await sourcePoint_v2_0() // SourcePoint CMP
        } catch (e) {
          console.error('Error with SourcePoint Consent manager')
        } finally {
          userStatus() // Piano + Axate + Google Extended Access
          console.log(`finished consent block at ${performance.now()}ms`)
        }
      },
      // Audience Data
      dependents: {
        initAirGrid, // Airgrid audience segmentation
        initAssertiveYield, // Assertive Yield ads script
        getGdprConsentToken, // Generate GDPR Consent Token and set Permutive consent
      },
    },
  },
  {
    initGPTorOptOut: () => {
      // GPT or Opt Out
      // Check for user consent value in local storage
      const consentKeyPrefix = '_sp_user_consent_'

      // Check SourcePoint consent data
      const getSpData = (): string | null => {
        const consentKey = Object.keys(localStorage).find((key) =>
          key.startsWith(consentKeyPrefix),
        )
        return consentKey ? localStorage.getItem(consentKey) : null
      }

      const parseGdprData = (spData: string | null) =>
        spData ? JSON.parse(spData) : null

      const hasConsent = (gdprData: any): boolean =>
        !gdprData?.gdpr?.consentStatus?.rejectedLI

      const spData = getSpData()
      const gdprData = parseGdprData(spData)
      const hasUserConsented = hasConsent(gdprData)

      // User has consented to third party cookies
      if (hasUserConsented) {
        initAds()
      } else if (
        // User has rejected consent but is on an Opt Out trial publication
        window.JSGlobals &&
        optOutTrialSites.includes(window.JSGlobals.domain)
      ) {
        initOptOutTag(window.JSGlobals.domain)
      }
    },
    initDailyMotion: async () => {
      await initDailyMotion() // DailyMotion video player
    },
    initMantis, // Mantis initialization
  },
]

const initPromises = (inits: any) =>
  Object.keys(inits).map((key) => async () => {
    const initFunc = inits[key]
    if (!initFunc) {
      return
    }
    try {
      if (initFunc.method) {
        await initFunc.method()
        if (initFunc.dependents) {
          await Throttle.all(initPromises(initFunc.dependents))
        }
      } else {
        await initFunc()
      }
    } catch (e) {
      console.warn(`Error caught in ${key}()`)
      console.error(e)
    }
  })

const initialiseModules = async () => {
  for (let i = 0; i < initialisers.length; i++) {
    await Throttle.all(initPromises(initialisers[i]))
  }
}

initialiseModules()
