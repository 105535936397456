// @TODO: Remove the eslint rule - this seems to be due to the boilerplate Permutive code
/* eslint-disable @typescript-eslint/no-unused-expressions */
/*globals JSGlobals */
import { jsLoader } from './fileLoaders'
import { getPermutiveIDs } from '~/constants/permutiveIDs'

const { ORGANIZATION_ID, WORKSPACE_ID, WORKSPACE_PUBLIC_API_KEY } =
  getPermutiveIDs()

// Global storage for Permutive data for access by Dailymotion
if (typeof window !== 'undefined') {
  window.permutiveData = {}
}

/**
 * Safely parse a JSON string with a fallback default value.
 * If the input is already an array, return it as is.
 */
const safeParseJSON = (data, defaultValue) => {
  try {
    if (Array.isArray(data)) {
      return data // Return as is if already an array
    }
    return JSON.parse(data) || defaultValue
  } catch (err) {
    console.error('Failed to parse JSON:', data, err)
    return defaultValue // Return the default on error
  }
}

/**
 * Initialize the Permutive tag.
 */
export const initPermutiveTag = () => {
  jsLoader(
    [`https://${ORGANIZATION_ID}.edge.permutive.app/${WORKSPACE_ID}-web.js`],
    'permutive',
  )
    .then(() => {
      !(function (e, o, n, i) {
        if (!e) {
          e = e || {}
          window.permutive = e
          e.q = []

          // Generate unique viewId if crypto is available
          const generateUUID = () =>
            ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (a) =>
              (
                a ^
                ((crypto.getRandomValues(new Uint8Array(1))[0] & 15) >> (a / 4))
              ).toString(16),
            )

          e.config = i || {}
          e.config.apiKey = o
          e.config.workspaceId = n
          e.config.environment = e.config.environment || 'production'
          e.config.viewId = window.crypto ? generateUUID() : null

          // Store Permutive data globally for other scripts
          window.permutiveData.viewId = e.config.viewId
          window.permutiveData.workspaceId = e.config.workspaceId

          const segmentMethods = [
            'addon',
            'identify',
            'track',
            'trigger',
            'query',
            'segment',
            'segments',
            'ready',
            'on',
            'once',
            'user',
            'consent',
          ]

          segmentMethods.forEach((method) => {
            e[method] = function () {
              const args = Array.prototype.slice.call(arguments)
              e.q.push({ functionName: method, arguments: args })
            }
          })
        }
      })(window.permutive, WORKSPACE_PUBLIC_API_KEY, WORKSPACE_ID, {
        consentRequired: true,
      })

      // Store user ID and segments immediately
      const storedPermutiveId = window.localStorage.getItem('permutive-id')
      const storedSegments = window.localStorage.getItem('_pdfps')
      console.log('Raw _pdfps from localStorage:', storedSegments)

      // Safely handle segments
      window.permutiveData.userId = storedPermutiveId || null
      window.permutiveData.segments = safeParseJSON(storedSegments, [])

      const {
        article = {},
        articleType = '',
        pageId = '',
        pageType = '',
        paragraphCount = 0,
        premium = false,
        sensitive = false,
        title = '',
      } = JSGlobals

      window.permutive.addon('web', {
        page: {
          classifications_watson: {
            categories: '$alchemy_taxonomy',
            concepts: '$alchemy_concepts',
            emotion: '$alchemy_document_emotion',
            entities: '$alchemy_entities',
            keywords: '$alchemy_keywords',
            sentiment: '$alchemy_document_sentiment',
          },
          author: article?.authors[0]?.name || '',
          sections: article?.sections?.join(', ') || '',
          sensitive: sensitive,
          article: {
            title: article?.title || '',
          },
          premium: premium,
          pagetype: pageType,
          articleType: articleType || '',
          siteSections: article?.siteSections?.flat() || [],
          title: title,
          pageID: pageId,
          topics: {
            name: article?.topics?.map((topic) => topic.name) || [],
          },
          paragraphCount: parseInt(paragraphCount) || 0,
        },
      })

      // Delayed execution for `pubAds` setup
      window.googletag = window.googletag || {}
      window.googletag.cmd = window.googletag.cmd || []
      window.googletag.cmd.push(setupPubAdsTargeting)
    })
    .catch((error) => {
      console.error('Error loading Permutive script:', error)
    })
}

/**
 * Targeting function for `pubAds`.
 */
const setupPubAdsTargeting = () => {
  if (window.googletag && window.googletag.pubads) {
    const permutiveData = window.permutiveData
    if (!window.googletag.pubads().getTargeting('permutive').length) {
      window.googletag
        .pubads()
        .setTargeting('permutive', permutiveData.segments || [])
      if (permutiveData.userId) {
        window.googletag.pubads().setTargeting('puid', permutiveData.userId)
        window.googletag.pubads().setTargeting('ptime', Date.now().toString())
      }
      if (permutiveData.viewId) {
        window.googletag.pubads().setTargeting('prmtvvid', permutiveData.viewId)
      }
      if (permutiveData.workspaceId) {
        window.googletag
          .pubads()
          .setTargeting('prmtvwid', permutiveData.workspaceId)
      }
    }
  }
}

/**
 * Retrieve Permutive targeting data.
 */
export const getPermutiveTargetingData = () => {
  const {
    segments = [], // Default to an empty array
    userId = null,
    viewId = null,
    workspaceId = null,
  } = window.permutiveData

  return {
    permutive: safeParseJSON(segments, []), // Array of user segments
    puid: userId, // Permutive user ID
    ptime: Date.now().toString(), // Current timestamp
    prmtvvid: viewId, // Permutive view ID
    prmtvwid: workspaceId, // Permutive workspace ID
  }
}
