import { Domain } from '~/types'
import {
  BLACKLISTED_ARTICLE_TYPES,
  BLACKLISTED_SECTIONS,
  WHITELISTED_DOMAINS,
} from './config'

const isWhitelistedDomain = (domain: Domain): boolean =>
  WHITELISTED_DOMAINS.includes(domain)

const isBlacklistedArticleTypes = (pageType: string): boolean =>
  BLACKLISTED_ARTICLE_TYPES.includes(pageType)

const isBlacklistedSection = (
  sections: { name: string }[] | undefined,
): boolean | undefined =>
  sections?.some((section) => BLACKLISTED_SECTIONS.includes(section.name))

export const shouldGetContextualVideo = (
  domain: Domain,
  articleType: string,
  sections: { name: string }[] | undefined,
  isAffiliated: boolean,
  isSensitive: boolean,
  isProductArticle?: boolean,
): boolean => {
  const isContextualVideoBlacklisted =
    !isWhitelistedDomain(domain) ||
    isBlacklistedArticleTypes(articleType) ||
    isBlacklistedSection(sections) ||
    isAffiliated ||
    isSensitive ||
    isProductArticle

  if (isContextualVideoBlacklisted) {
    return false
  }

  return true
}
