import { jsLoader } from './fileLoaders'

interface Article {
  DMVideoId?: string
  isCommercial?: boolean
  isPhotoArticle?: boolean
}

export const DATA_FLUID_ZONE_ID_SPORT = '19039'
export const DATA_FLUID_ZONE_ID_BUSINESS = '19041'
export const DATA_FLUID_ID = 'e6404a5432b1988ea2e71ec092e8608a'

export const pathnameToZoneId = {
  sport: DATA_FLUID_ZONE_ID_SPORT,
  business: DATA_FLUID_ZONE_ID_BUSINESS,
}

export const shouldGetSkyPlayer = (
  article: Article,
  domain: string,
): boolean => {
  const mediaId = article.DMVideoId
  const pathSection = window.location.pathname.split('/')[1]
  const isSportsArticle = pathSection === 'sport'
  const isBusinessArticle = pathSection === 'business'
  const isCommercialArticle = article?.isCommercial
  const skyPlayerBlacklistedSites = ['totallysnookered.com']
  const isSkyPlayerBlacklistedSite = skyPlayerBlacklistedSites.includes(domain)
  const currentPage = new URLSearchParams(window.location.search).get('page')
  const isOnFirstPage = !currentPage || currentPage === '1'

  if (mediaId) return false // don't show if a hero video already exists
  if (isCommercialArticle) return false
  if (isSkyPlayerBlacklistedSite) return false
  if (!isSportsArticle && !isBusinessArticle) return false // only show on Sports & Business articles
  if (article.isPhotoArticle && !isOnFirstPage) return false

  return true
}

export const getSkyPlayer = (): void => {
  jsLoader(['//fluid.4strokemedia.com/www/fluid/player.php'], 'skyplayer')
  // Replace Dailymotion Player with SkyPlayer
  const pathSection = window.location.pathname.split('/')[1]
  const DATA_FLUID_ZONE_ID =
    pathnameToZoneId[pathSection as keyof typeof pathnameToZoneId]
  const dailymotionDiv = document.getElementById('hero-slot')
  const skyPlayerDiv = document.createElement('div')
  skyPlayerDiv.id = 'sky-player'
  const insTag = document.createElement('ins')
  insTag.setAttribute('data-fluid-zoneid', DATA_FLUID_ZONE_ID)
  insTag.setAttribute('data-fluid-id', DATA_FLUID_ID)
  skyPlayerDiv.appendChild(insTag)

  if (dailymotionDiv) {
    dailymotionDiv.replaceWith(skyPlayerDiv)
  }

  return
}
